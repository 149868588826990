.homeHeader {
  height: auto;
  --opacidad-negro: 0.6;
  background-image: linear-gradient(
      rgba(0, 0, 0, var(--opacidad-negro)),
      rgba(0, 0, 0, var(--opacidad-negro))
    ),
    url("./assets/herofondo.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
}
.nav {
  transition: 0.5s;
}
.navBarLinks {
  color: red;
  text-decoration: none !important;
}
.aboutHeader {
  --opacidad-negro: 0.5;
  background-image: linear-gradient(
      rgba(0, 0, 0, var(--opacidad-negro)),
      rgba(0, 0, 0, var(--opacidad-negro))
    ),
    url("./assets/aboutUs.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
}
.registerHeader {
  --opacidad-negro: 0.5;
  background-image: linear-gradient(
      rgba(0, 0, 0, var(--opacidad-negro)),
      rgba(0, 0, 0, var(--opacidad-negro))
    ),
    url("./assets/register.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
}

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  min-height: 100px;
  max-height: 100px;
}

.parallax > use {
  -webkit-animation: moveForever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  animation: moveForever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}

.parallax > use:nth-child(1) {
  -webkit-animation-delay: -2s;
  animation-delay: -2s;
  -webkit-animation-duration: 7s;
  animation-duration: 7s;
}

.parallax > use:nth-child(2) {
  -webkit-animation-delay: -3s;
  animation-delay: -3s;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
}

.parallax > use:nth-child(3) {
  -webkit-animation-delay: -4s;
  animation-delay: -4s;
  -webkit-animation-duration: 13s;
  animation-duration: 13s;
}

.parallax > use:nth-child(4) {
  -webkit-animation-delay: -5s;
  animation-delay: -5s;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
}

@-webkit-keyframes moveForever {
  0% {
    -webkit-transform: translate3d(-90px, 0, 0);
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(85px, 0, 0);
    transform: translate3d(85px, 0, 0);
  }
}

@keyframes moveForever {
  0% {
    -webkit-transform: translate3d(-90px, 0, 0);
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(85px, 0, 0);
    transform: translate3d(85px, 0, 0);
  }
}
@media (max-width: 991px) {
  .nav {
    background-color: #222;
  }
}
@media (max-width: 950px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }
}
