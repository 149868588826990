:root {
  --primerColor:#023B20; 
  --segundoColor:#105B34;
  --tercerColor:#039247;
}
html {
  scroll-behavior: smooth;
}
html, body {
  position: relative;
}