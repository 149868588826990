.gallerySection{
    min-height: 85vh;
    width: 100%;
}
.gallery {
    width: 90%;
}
.iconSize{
    height: 70px;
    width: 70px;
    font-size: 25px;
    color: white;
}
.linea{
    position: relative;
}
.linea::before {
    content:' ';
    position: absolute;
    height: 3px;
    width: 50px;
    background-color: var(--segundoColor);; 
    top: -10px;
}
.contenedor{
    width: 85%;
    position: relative;
    padding-top: 20px;
}
.contenedor::before{
    content:' ';
    position: absolute;
    height: 4px;
    width: 50px;
    background-color: var(--segundoColor);; 
    top: 0.5rem;
}
.contenedor a:link, a:visited, a:active {
    text-decoration:none;
    color: var(--segundoColor);;
}
.arrow {
    margin-left: 30px;
}
.contenedor h1 {
    color: var(--segundoColor);
}
@media screen and (max-width: 768px){
    .contenedor{ width: 100%;}
 }