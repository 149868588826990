.cover {
  height: auto;
  padding-top: 2em;
  padding-bottom: 2em;
  width: 100%;
  --opacidad-negro: 0.6;
  background-image: linear-gradient(
      rgba(0, 0, 0, var(--opacidad-negro)),
      rgba(0, 0, 0, var(--opacidad-negro))
    ),
    url("./assets/cover-img.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.contenedor {
  width: 49rem;
}
/* =================================== Area informativa =================================== */
.textContainer {
  width: 24em;
  padding: 10px;
  height: 40rem;
}

.frase {
  color: var(--tercerColor);
  font-size: 0.9rem;
}

.frase::before {
  content: " ";
  position: absolute;
  height: 2px;
  width: 15px;
  background-color: gray;
  top: -1rem;
}

.contactUsTitle {
  color: white;
  font-size: 3rem;
}

.contactUsTitle::before {
  content: " ";
  position: absolute;
  height: 2px;
  width: 50px;
  background-color: gray;
  top: -0.8rem;
}

.contactUsText {
  color: white;
  font-size: 1.1rem;
}

.contactUsText::before {
  content: " ";
  position: absolute;
  height: 3px;
  width: 37px;
  background-color: var(--tercerColor);
  top: -1rem;
}

/* =================================== redes sociales etc =================================== */
.contactUsText2 {
  width: 100%;
}

.contactUsText2 .cont1 {
  width: 50%;
  height: 14.5rem;
  padding-top: 18px;
}

.contactUsText2 .cont2 {
  padding-left: 30px;
  width: 50%;
  height: 14.5rem;
  padding-top: 18px;
}

.listItem {
  color: white;
  font-size: 15px;
  display: flex;
}
.listIcon {
  font-size: 15px;
  color: var(--tercerColor);
  margin-right: 10px;
}

.contactUsText2 .cont1 b {
  font-size: 0.8rem;
  color: white;
  position: relative;
}
.contactUsText2 .cont1 b::before {
  content: " ";
  position: absolute;
  height: 2px;
  width: 15px;
  background-color: gray;
  top: -0.8rem;
}

.contactUsText2 .cont2 b {
  font-size: 0.8rem;
  color: white;
  position: relative;
}

.contactUsText2 .cont2 b::before {
  content: " ";
  position: absolute;
  height: 2px;
  width: 15px;
  background-color: gray;
  top: -0.8rem;
}
/* =================================== formulario==================================== */
.formSection {
  padding-top: 45px;
  width: 21rem;
  margin-left: 40px;
  min-height: 30px;
  color: white;
}

.formTitle2 {
  position: relative;
  font-size: 1.7rem;
}
.formTitle2::before {
  content: " ";
  position: absolute;
  height: 2px;
  width: 25px;
  background-color: gray;
  top: -1rem;
}
.formContainer {
  position: relative;
  margin-top: 30px;
  height: 19.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.formContainer::before {
  content: " ";
  position: absolute;
  height: 3px;
  width: 40px;
  background-color: var(--tercerColor);
  top: -0.9rem;
}

.formContainer input {
  height: 35px;
  border-radius: 20px;
  border-style: none;
  background-color: rgba(255, 255, 255, 0.2);
  padding-left: 20px;
  color: white;
}
.formContainer input::placeholder {
  color: #cacaca;
  font-size: 0.8rem;
}

.formContainer textarea {
  height: 75px;
  border-radius: 15px;
  background-color: rgba(255, 255, 255, 0.2);
  padding-left: 20px;
  color: white;
}

.formContainer textarea::placeholder {
  color: #cacaca;
  font-size: 0.8rem;
}

.formContainer button {
  height: 37px;
  border-radius: 20px;
  border-style: none;
  color: white;
  background-color: var(--tercerColor);
  font-size: 0.8rem;
}

/* ====================================== responsive ===================================*/
@media screen and (max-width: 768px) {
  .cover {
    width: 100%;
  }
  .contenedor {
    width: 90%;
  }
  .textContainer {
    width: 100%;
  }
  .formSection {
    width: 100%;
    margin-left: 20px;
  }
  .formContainer {
    width: 90%;
  }
}
