.whoWeAre_WhySection{
    min-height: 60vh;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}
.whoWeAre_WhyContainer{ width: 90%;}
.textContent { width: 35rem;}
.whoWeAre_WhyImg{ height: 25rem;}

.textContent h3 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: var(--segundoColor);
}
/* ----------------------------------------- */

.WhatWeDoSection {
    min-height: 75vh;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}
.WhatWeDoTitle { width: 80%;}
.WhatWeDoTitle h1{ font-size: 4rem;}
.WhatWeDoTitle h1 span {color: var(--segundoColor)}
.WhatWeDo{width: 90%;}
/* --------------- WE ARE CREATIVE ------------- */
.WeAreCreative {
    margin-top: 50px;
    height: 19rem;
    width: 100%;
    --opacidad-negro: 0.6;
    background-image: linear-gradient(rgba(0, 0, 0, var(--opacidad-negro)), rgba(0, 0, 0, var(--opacidad-negro))), url("./assets/weAreCreative.jpg"); background-repeat: no-repeat; background-position: center; background-size: cover;
}
.WeAreCreative h1{ font-size: 3rem; color: white; }

/* ----------------------- Map ------------------- */
.mapTitle { width: 90%; min-height: 21vh;}
.mapTitle h1{ font-size: 4rem;}
.mapTitle h1 span {color: var(--segundoColor)}
.mapSection {
    height:auto;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
}
.mapInfo{
    min-height:60vh;
    width: 50%;
    --opacidad-negro: 0.6;
    background-image: linear-gradient(rgba(0, 0, 0, var(--opacidad-negro)), rgba(0, 0, 0, var(--opacidad-negro))), url("./assets/weAreCreative.jpg");background-repeat: no-repeat; background-position: center; background-size: cover;
}
.mapView{
    height: 60vh;
    width: 55%;
    gap: 3em;
}
.mapa {width: 60%; height: 60vh;}
.mapContainer{
    height: auto;
    width: 100%;
}
/* -------------------------------------------- */

@media screen and (max-width: 991px){
    .textContent{width: 100%;}
    .whoWeAre_WhyImg{margin-top: 30px;}
    .mapContainer{width: 100%;}
    .mapa{width: 100%; min-height: 50vh;}
    .mapView{width: 80%; min-height: 20vh;}
    .mapInfo{width: 100%; min-height: 20vh;}
 }
 @media screen and (max-width: 720px){
    .whoWeAre_WhyImg{ width: 100%;}
 }