.registerSection{
    height: auto;
    width: 100%;
    margin-top: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
    --opacidad-negro: 0.6;
    background-image: linear-gradient(rgba(0, 0, 0, var(--opacidad-negro)), rgba(0, 0, 0, var(--opacidad-negro))), url("./assets/docs.jpg");
}
.InstructionSection{margin-top: 70px;}
.registerContainer{
    height: 90%;
    width: 85%;
    border-radius: 20px;
    box-shadow: 0px 0px 25px -5px rgba(0,0,0,0.5);
}
.registerImg{
    --opacidad-negro2: 0.4;
    background-image: linear-gradient(rgba(0, 0, 0, var(--opacidad-negro2)), rgba(0, 0, 0, var(--opacidad-negro2))), url("./assets/registroImg.jpg"); background-position: center; background-size: cover; background-repeat: no-repeat;
    height: auto;
    width: 45%;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    color: white;
    font-size: 30vh;
}
.register{
    width: 55%;
    height: 100%;
    background-color: white;
    padding:20px;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
}
.grupoDeBotones {
    position: relative;
    margin-left: 30px;
}
.btnRegistrar {
    display: flex;
    gap: 30px;
}
.stepsCard{
    height: 20em;
    width: 17em;
}
.stepsIcon{
    font-size: 4em;
    text-align: center;
}
.stepsText{
    text-align: center;
}
.stepsText h3{
    color: var(--tercerColor);
}
@media (max-width : 767px){
    .registerImg {
        width: 100%;
        height: 40vh;
        border-top-right-radius: 20px;
        border-bottom-left-radius:0px;
    }
    .register {
        width: 100%;
        border-bottom-left-radius: 20px;
        border-top-right-radius:0px;
    }
}