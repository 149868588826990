footer{
   min-height: 59.5vh;
   width: 100%;
   background-color: white;
}
.contenedor{
   margin-top: 5.313em;
   width: 88%;
}
.area{ min-height: 15em; }
.area p {
   font-size: 1.1em;
   letter-spacing: 0.2px;
}
.area a { color: black;}
.area h3 { font-size: 1.5em; color: var(--segundoColor);}
.logoArea{ height: 4.375em;}

.color{
   background-color: var(--tercerColor);
   height: 2.813rem;
   width: 2.813rem;
   font-size: 30px;
   color: white;
}
.logoArea h2{
   font-size: 1.8rem;
   margin-left: 0.75rem;
   margin-top:0.375rem;
   color: var(--segundoColor);
}

.area2{
   min-height: 15em;
}
.listContainer{
   width: 50%;
}
.listContainer h4 {
   font-size: 1.4em;
   margin-bottom: 35px;
   color:var(--segundoColor);
}

li{
   margin-top: 0.938em;
   font-size: 1.1em
}

.emailBtn{
   height: 3.438rem;
   background-color: white;
   border: 1px solid var(--segundoColor);
   border-radius: 1.875em;
   font-size: 1.2em;
   padding-left: 1.438em;
   padding-right: 1.438em;
}
.iconBtn{ font-size: 1.9rem;}

.socialContainer{
   margin-top: 0.625em;
   height: 3.125rem;
   width: 45%;
   font-size: 1.563rem;
}
.autor{ color: var(--segundoColor); }

@media screen and (max-width: 991px){
   .listContainer{width: 30%;}
   .colR{ margin-top: 3.125em;}
   .contenedor{margin-top:2.313em}
}
